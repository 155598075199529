<script setup>
import { onMounted, ref, watch, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { returnLanguage } from '../../assets/js/language'
import AboutBanner from './components/about-banner'
import { useRoute } from 'vue-router'
import { getInfo } from '@/api/new'
const store = useStore()
// 拿到vuex中的分类列表
const langtype = store.state.home.type
const langvalue = ref(returnLanguage(langtype))
let route = useRoute()
let id = ref(route.query.id)
let content = ref('')
const checkedtype = ref(langvalue.value.news.one)
const type = ref(langtype)

let getInfoK = async () => {
  let { data } = await getInfo({ id: id.value })
  content.value = data.content
}
getInfoK()
watch(
  () => store.state.home.type,
  newVal => {
    langvalue.value = returnLanguage(newVal)
    checkedtype.value = returnLanguage(newVal).news.one
    type.value = newVal
    getInfoK()
  },
  { immediate: true }
)
</script>
<template>
  <div class="connew">
    <AboutBanner />
    <div class="new_main">
      <div class="new_info" v-html="content"></div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.connew {
  background: #f8f8f8;
  .new_main {
    background: #fff;
    // border: 1px solid red;
    width: 1340px;
    padding: 20px 100px 200px;
    margin: -280px auto 0;
    .zx_title {
      display: flex;
      align-items: center;
      .icon {
        width: 40px;
        height: 40px;
        margin-right: 14px;
      }
      p {
        color: #666;
        font-family: 'PingFang SC';
        font-size: 24px;
        font-style: normal;
      }
    }
    .new_info {
      min-height: 300px;
    }
    .new_list {
      // border: 1px solid red;
      ._list_title {
        border-bottom: 1px solid #d3d5e0;
        line-height: 28px;
        padding: 8px 0;
        span {
          color: rgba(0, 0, 0, 0.8);
          padding: 2px 0;
          font-family: 'PingFang SC';
          font-size: 50px;
          font-style: normal;
          font-weight: 400;
          border-bottom: 2px solid #0188ff;
        }
      }
    }
  }
}
</style>
